<template>
  <!-- alert button -->
  <div class="all-alert-button-wrapper" :class="[callIsActive ? 'all-alert-button-wrapper-to-center' : '']">
    <div @click="call" class="alert-button"
      :class="[(callIsActive ? 'alert-button-close alert-button-to-center' : 'alert-button-call')]">
      <i class="fa-solid fa-circle-xmark"></i>
    </div>
    <div @click="call" :class="[(waitingSend ? 'alert-button-disabled' : '')]" class="alert-button-fake"></div>

    <div v-if="$store.state.clientDesk && (AlertButtonSettings && AlertButtonSettings.Hookah == true)"
      :class="[(callIsActive ? 'alert-button-to-bottom' : ''), (waitingSend ? 'alert-button-disabled' : '')]"
      @click="callPersonal('hookah')" class="alert-button alert-button-hookah">
      <div class="alert-button-title-wrapper">
        <div :class="[callIsActive ? 'alert-button-title-show' : '']"
          class="alert-button-title alert-button-title-to-bottom">
          {{ $t('CallHookah') }}
        </div>
      </div>
    </div>
    <div v-if="$store.state.clientDesk && (AlertButtonSettings && AlertButtonSettings.Waiter == true)"
      :class="[(callIsActive ? 'alert-button-to-left' : ''), (waitingSend ? 'alert-button-disabled' : '')]"
      @click="callPersonal('waiter')" class="alert-button alert-button-waiter">
      <div class="alert-button-title-wrapper">
        <div :class="[callIsActive ? 'alert-button-title-show' : '']"
          class="alert-button-title alert-button-title-to-top">
          {{ $t('CallWaiter') }}
        </div>
      </div>
    </div>
    <div v-if="$store.state.clientDesk && (AlertButtonSettings && AlertButtonSettings.Bill == true)"
      :class="[(callIsActive ? 'alert-button-to-right' : ''), (waitingSend ? 'alert-button-disabled' : '')]"
      @click="callPersonal('bill')" class="alert-button alert-button-check">
      <div class="alert-button-title-wrapper">
        <div :class="[callIsActive ? 'alert-button-title-show' : '']"
          class="alert-button-title alert-button-title-to-top">
          {{ $t('CallCheck') }}
        </div>
      </div>
    </div>
    <div v-if="$store.state.clientDesk && (AlertButtonSettings && AlertButtonSettings.Pos == true)"
      :class="[(callIsActive ? 'alert-button-to-top' : ''), (waitingSend ? 'alert-button-disabled' : '')]"
      @click="callPersonal('pos')" class="alert-button alert-button-pos">
      <div class="alert-button-title-wrapper">
        <div :class="[callIsActive ? 'alert-button-title-show' : '']"
          class="alert-button-title alert-button-title-to-top">
          {{ $t('CallPos') }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="callIsActive" class="all-alert-button-overlay">
    <span v-if="waitingSend">{{ $t('CallWaiting') }}... ⏳</span>
  </div>
  <v-dialog v-model="dialogCall" width="40%" style="padding: 20px;">
    <v-card>
      <div class="text-center dialog-call-smile">
        👍
      </div>
      <div class="text-center dialog-call-text">
        {{ $t('CallOk') }} <span>😉</span>
      </div>
      <v-card-actions>
        <v-btn @click="dialogCall = false"><i class="fa fa-check-square" aria-hidden="true"></i>&nbsp;ОК </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogCallError" width="40%" style="padding: 20px;">
    <v-card>
      <div class="text-center dialog-call-smile">
        😈
      </div>
      <div class="text-center dialog-call-text">
        {{ $t('CallBad') }}
      </div>
      <v-card-actions>
        <v-btn @click="dialogCallError = false"><i class="fa fa-check-square" aria-hidden="true"></i>&nbsp;{{
          $t('CallUnd') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- alert button end-->
</template>
<script>
export default {
  name: 'AlertButton',
  props: {
    open: Boolean,
    AlertButtonSettings: Object
  },
  emits: ['close'],
  data() {
    return {
      callIsActive: false,
      waitingSend: false,
      dialogCall: false,
      dialogCallError: false
    }
  },
  watch: {
    open() {
      this.callIsActive = this.open
    }
  },
  methods: {
    callPersonal(personal) {
      if (!this.$store.state.clientDesk) return
      if (this.waitingSend) {
        this.$notify({
          title: this.$t("CallWaiting"),
          type: "warn",
          duration: 2000,
        });
        return;
      }
      this.waitingSend = true;
      this.axios.post(this.$api_link + "/api/call/" + personal + "?d=" + this.$store.state.clientDesk)
        .then(function (response) {
          if (response.status == 200) {
            this.dialogCall = true;
          }
          this.waitingSend = false;
          this.callIsActive = false;
          this.$emit('close');
        }.bind(this)
        )
        .catch(
          function (err) {
            this.waitingSend = false;
            if (err && err.response && err.response.status == 429) {
              this.dialogCallError = true;
              this.callIsActive = false;
              this.$emit('close');
            } else {
              this.$notify({
                title: this.$t("ApiErrorText"),
                type: "error",
                duration: 5000,
              });
            }
          }.bind(this)
        );
    },
    call() {
      if (this.waitingSend) {
        this.$notify({
          title: this.$t("CallWaiting"),
          type: "warn",
          duration: 2000,
        });
        return;
      }
      this.waitingSend = false;
      if (this.callIsActive) {
        this.callIsActive = false;
        this.$emit('close');
      } else {
        this.callIsActive = true;
      }
    }
  },
}
</script>
<style scoped lang="scss">
.v-card {
  border-radius: 20px !important;
}

.v-card-actions .v-btn__content {
  font-size: 16px !important;
}

.dialog-call-text {
  margin: 10px 0 !important;
  font-size: 18px;
  font-weight: bold;

  span {
    font-size: 25px;
  }
}

.dialog-call-smile {
  font-size: 61px !important;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.all-alert-button-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  color: #fff !important;
  font-weight: bold;
  font-size: 16px;
  padding-top: 16px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.all-alert-button-wrapper-to-center {
  visibility: visible !important;
  opacity: 1 !important;
  bottom: 50% !important;
  right: 50% !important;
  -webkit-transform: translateX(50%) translateY(50%);
  -moz-transform: translateX(50%) translateY(50%);
  -o-transform: translateX(50%) translateY(50%);
  transform: translateX(50%) translateY(50%);
}

.all-alert-button-wrapper {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  width: 80px;
  height: 80px;
  z-index: 1001;
  border-radius: 50%;
  background-color: #fff;

  .alert-button-disabled {
    background-color: gray !important;
  }

  .alert-button {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 50%;
    background-size: 70%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;

    .alert-button-title-wrapper {
      position: relative;
      height: 100%;

      .alert-button-title-to-top {
        top: -25px;
      }

      .alert-button-title-to-bottom {
        bottom: -25px;
      }

      .alert-button-title-show {
        visibility: visible !important;
        opacity: 1 !important;
      }

      .alert-button-title {
        opacity: 0;
        transition: 0.5s ease-in-out;
        transition-delay: 0, 5s;
        visibility: hidden;
        position: absolute;
        width: 250%;
        color: #fff;
        left: -75%;
        text-align: center;
      }
    }
  }

  .alert-button-to-bottom {
    top: 100px !important;
  }

  .alert-button-to-top {
    top: -100px !important;
  }

  .alert-button-to-left {
    left: -100px !important;
  }

  .alert-button-to-right {
    left: 100px !important;
  }

  .alert-button-pos {
    background-image: url(/public/assets/images/pos.png);
    background-color: #fff;
    border: 3px solid #4449E5;
    cursor: pointer;
    animation: none !important;
    transition: all ease-in-out 0.5s;
  }

  .alert-button-check {
    background-image: url(/public/assets/images/bill.png);
    background-color: #fff;
    border: 3px solid #4449E5;
    cursor: pointer;
    animation: none !important;
    transition: all ease-in-out 0.5s;
  }

  .alert-button-waiter {
    background-image: url(/public/assets/images/waiter.png);
    background-color: #fff;
    border: 3px solid #4449E5;
    cursor: pointer;
    animation: none !important;
    transition: all ease-in-out 0.5s;
  }

  .alert-button-hookah {
    background-image: url(/public/assets/images/shisha.png);
    background-color: #fff;
    border: 3px solid #4449E5;
    cursor: pointer;
    animation: none !important;
    transition: all ease-in-out 0.5s;
  }

  .alert-button-call {
    background-image: url(/public/assets/images/bell.png);
    z-index: 1003 !important;
    background-size: 65%;
  }

  .alert-button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1003 !important;
    font-size: 40px;
    color: #007aff;
  }

  .alert-button-fake {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    border-radius: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    border: 3px solid #4449E5;
    cursor: pointer;
  }
}
</style>
