import axios from 'axios'
import { apiLink, storageVersion } from '@/config'

export default {
    state: {
        basket: JSON.parse(localStorage.getItem('basket')) || [],
        basketTotalAmount: 0
    },
    mutations: {
        addItemToBasket(state, l) {
            state.basket.push(l);
            localStorage.setItem("basket", JSON.stringify(state.basket));
        },
        removeItemFromBasket(state, l) {
            state.basket = state.basket.filter((item) => item.id != l);
            localStorage.setItem("basket", JSON.stringify(state.basket));
        },
        clearBasket(state) {
            state.basket = [];
            localStorage.setItem("basket", JSON.stringify(state.basket));
        },
        changeItemDetailsInBasket(state, l) {
            const itemIndex = state.basket.findIndex((item) => item.id == l.id);
            if (itemIndex !== -1) {
                Object.assign(state.basket[itemIndex], l);
                localStorage.setItem('basket', JSON.stringify(state.basket));
            }
        },
        setBasketTotalAmount(state, l) {
            state.basketTotalAmount = l;
        },
        restoreBasket(state) {
            const currentStoreVersion = localStorage.getItem('osvb')
            if (currentStoreVersion != storageVersion) {
                state.basket = [];
                localStorage.setItem("basket", JSON.stringify(state.basket));
                localStorage.setItem("osvb", storageVersion);
            }
        }
    },
    actions: {
        addItemToBasket(context, l) {
            const item = {
                id: l,
                count: 1,
                comment: ''
            }
            context.commit('addItemToBasket', item)
        },
        removeItemFromBasket(context, l) {
            context.commit('removeItemFromBasket', l)
        },
        clearBasket(context, l) {
            context.commit('clearBasket')
        },
        changeItemDetailsInBasket(context, l) {
            context.commit('changeItemDetailsInBasket', l)
        },
        async getBasketTotal(context) {
            const userBasket = context.state.basket
            if (userBasket.length) {
                try {
                    const basketItemsInfo = await axios.post(apiLink + "/api/favorites", context.state.basket.map((item) => item.id))
                    const totalAmount = basketItemsInfo?.data.reduce((accumulator, item) => {
                        const userCount = userBasket.find(itemUserBasket => itemUserBasket.id == item.id)?.count || 0
                        return accumulator + (Number(item.price) * userCount)
                    }, 0)
                    context.commit('setBasketTotalAmount', totalAmount)
                } catch (error) {
                    context.commit('setBasketTotalAmount', null)
                }
            } else {
                context.commit('setBasketTotalAmount', null)
            }
        },
        restoreBasket(context) {
            context.commit('restoreBasket')
        }
    },
    getters: {
        basket: state => state.basket,
        basketCount: state => state.basket.length,
        basketTotalAmount: state => state.basketTotalAmount,
        basketTotalAmountWithDelivery: (state, getters, rootState, rootGetters) => {
            const settings = rootGetters.settings
            const totalAmount = state.basketTotalAmount
            let deliveryPrice = 0
            if (settings?.delivery_free) {
                deliveryPrice = totalAmount >= settings.delivery_free ? 0 : (settings?.delivery_price || 0)
            } else {
                deliveryPrice = (settings?.delivery_price || 0)
            }
            return totalAmount + deliveryPrice
        },
        basketTotalAmountFormat: (state, getters) => getters.basketTotalAmount ? getters.basketTotalAmount.toFixed(2) : "--",
        basketTotalAmountWithDeliveryFormat: (state, getters) => getters.basketTotalAmountWithDelivery ? getters.basketTotalAmountWithDelivery.toFixed(2) : "--",
    },
};