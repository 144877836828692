<template>
  <AlertButtonComponent :AlertButtonSettings="AlertButtonSettings" :open="toogleButtonAlert"
    @close="toogleButtonAlert = false" />
  <div class="menubar-area style-4 footer-fixed border-top rounded-0">
    <div class="toolbar-inner menubar-nav">
      <router-link to="/" class="nav-link" exact>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.14373 20.7821V17.7152C9.14372 16.9381 9.77567 16.3067 10.5584 16.3018H13.4326C14.2189 16.3018 14.8563 16.9346 14.8563 17.7152V20.7732C14.8562 21.4473 15.404 21.9951 16.0829 22H18.0438C18.9596 22.0023 19.8388 21.6428 20.4872 21.0007C21.1356 20.3586 21.5 19.4868 21.5 18.5775V9.86585C21.5 9.13139 21.1721 8.43471 20.6046 7.9635L13.943 2.67427C12.7785 1.74912 11.1154 1.77901 9.98539 2.74538L3.46701 7.9635C2.87274 8.42082 2.51755 9.11956 2.5 9.86585V18.5686C2.5 20.4637 4.04738 22 5.95617 22H7.87229C8.19917 22.0023 8.51349 21.8751 8.74547 21.6464C8.97746 21.4178 9.10793 21.1067 9.10792 20.7821H9.14373Z"
            fill="#130F26"></path>
        </svg>
      </router-link>
      <router-link to="/favorites" class="nav-link" exact>
        <span class="order-wrap">
          <svg enable-background="new 0 0 512.001 512.001" height="512" viewBox="0 0 512.001 512.001" width="512"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m256.001 477.407c-2.59 0-5.179-.669-7.499-2.009-2.52-1.454-62.391-36.216-123.121-88.594-35.994-31.043-64.726-61.833-85.396-91.513-26.748-38.406-40.199-75.348-39.982-109.801.254-40.09 14.613-77.792 40.435-106.162 26.258-28.848 61.3-44.734 98.673-44.734 47.897 0 91.688 26.83 116.891 69.332 25.203-42.501 68.994-69.332 116.891-69.332 35.308 0 68.995 14.334 94.859 40.362 28.384 28.563 44.511 68.921 44.247 110.724-.218 34.393-13.921 71.279-40.728 109.632-20.734 29.665-49.426 60.441-85.279 91.475-60.508 52.373-119.949 87.134-122.45 88.588-2.331 1.354-4.937 2.032-7.541 2.032z">
            </path>
          </svg>
          <span v-if="$store.getters.favoritesCount > 0" class="order-count">
            {{ $store.getters.favoritesCount }}
          </span>
        </span>
      </router-link>
      <a @click.prevent="toogle"
        v-if="$store.state.clientDesk && (AlertButtonSettings && AlertButtonSettings.isActive == true)"
        href="javascript:void(0);" class="nav-link add-post">
        <i class="fa-solid fa-bell"></i>
      </a>
      <router-link to="/order" class="nav-link" exact>
        <span class="order-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.569 456.569">
            <path
              d="M345.805 339.465c-29.323-.028-53.117 23.72-53.146 53.043s23.72 53.117 53.043 53.146 53.117-23.72 53.146-53.043v-.051c-.028-29.292-23.752-53.038-53.043-53.095zm94.171-254.244a20.44 20.44 0 0 0-3.855-.373H112.845l-5.12-34.253c-3.19-22.748-22.648-39.673-45.619-39.68H20.48C9.169 10.915 0 20.084 0 31.395s9.169 20.48 20.48 20.48h41.677a5.12 5.12 0 0 1 5.12 4.506l31.539 216.166c4.324 27.468 27.951 47.732 55.757 47.821h213.043c26.771.035 49.866-18.78 55.245-45.005l33.331-166.144c2.149-11.105-5.111-21.849-16.216-23.998zM215.737 390.286c-1.247-28.463-24.737-50.869-53.228-50.77h0c-29.299 1.184-52.091 25.896-50.907 55.195 1.136 28.113 24.005 50.458 52.136 50.943h1.28c29.295-1.284 52.002-26.073 50.719-55.368z">
            </path>
          </svg>
          <span v-if="$store.getters.basketCount > 0" class="order-count">
            {{ $store.getters.basketCount }}
          </span>
        </span>
      </router-link>
      <router-link to="/info" class="nav-link" exact>
        <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
          <g>
            <g>
              <path
                d="M286.117,385.614c-16.606-16.607-43.629-16.607-60.235,0c-16.607,16.607-16.607,43.629,0,60.236
                c8.303,8.304,19.211,12.456,30.117,12.456s21.815-4.151,30.117-12.456C302.724,429.244,302.724,402.221,286.117,385.614z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M150.588,310.321l30.117,30.117c41.518-41.517,109.07-41.517,150.588,0l30.117-30.117
                C303.288,252.198,208.712,252.198,150.588,310.321z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M256,160.176c-68.262,0-132.438,26.582-180.706,74.851l30.117,30.117c40.223-40.223,93.704-62.376,150.588-62.376
                c56.884,0,110.365,22.153,150.588,62.376l30.117-30.117C388.438,186.758,324.262,160.176,256,160.176z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M256,53.694c-96.704,0-187.62,37.659-256,106.039l30.117,30.117C90.453,129.515,170.674,96.287,256,96.287
                s165.547,33.228,225.883,93.564L512,159.733C443.62,91.353,352.704,53.694,256,53.694z" />
            </g>
          </g>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import AlertButtonComponent from "./AlertButton.vue";
export default {
  name: "MenubarComponent",
  props: {
    AlertButtonSettings: Object
  },
  components: {
    AlertButtonComponent
  },
  data() {
    return {
      toogleButtonAlert: false
    }
  },
  methods: {
    toogle() {
      this.toogleButtonAlert = !this.toogleButtonAlert
    }
  }
};
</script>

<style scoped lang="scss">
.order-wrap {
  position: relative;

  .order-count {
    position: absolute;
    right: -20px;
    top: -10px;
    border-radius: 50%;
    background-color: #4449E5;
    text-align: center;
    height: 20px;
    width: 20px;
    line-height: 20px !important;
    color: #fff;
    font-size: 11px;
  }
}
</style>
