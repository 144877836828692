import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/menu/:aliace_sec/:aliace_sub_sec?',
    name: '',
    children: [
      {
        path: '',
        name: 'categories',
        component: () => import('../views/CategoriesView.vue'),
      },
      {
        path: 'category/:aliace_cat',
        name: 'categories.products',
        component: () => import('../views/ProductsView.vue')
      }
    ]
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('../views/FavoritesView.vue')
  },
  {
    path: '/order',
    name: '',
    children: [
      {
        path: '',
        name: 'order',
        component: () => import('../views/OrderView.vue'),
      },
      {
        path: 'delivery',
        name: 'order.delivery',
        component: () => import('../views/OrderDeliveryView.vue'),
      },
      {
        path: 'confirm',
        name: 'order.confirm',
        component: () => import('../views/OrderConfirmView.vue')
      }
    ]
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/InfoView.vue')
  },
  { path: '/:pathMatch(.*)*', component: () => import('../views/ErrorPage.vue') }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes
})

router.beforeEach(async () => {
  const url = new URL(location.href);
  const searchParams = url.searchParams;
  var client_desk_ = searchParams.get('d');
  if (client_desk_ != null) {
    client_desk_ = client_desk_.substring(0, client_desk_.length - 1);
  }
  store.dispatch("setClientDesk", client_desk_);
})

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})


export default router
