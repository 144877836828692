import Cookies from 'js-cookie';
import { cookieExpireDays } from '@/config'

export default {
    state:{
        user: (Cookies.get('user-info') ? JSON.parse(Cookies.get('user-info')) : null),
        formRequired: false,
    },
    mutations: {
        setUserInfo (state, l) {
            const serializeUserData = JSON.stringify(l)
            state.user = l
            Cookies.set('user-info', serializeUserData, { expires: cookieExpireDays })
        },
        setFormRequired (state, l) {
            state.formRequired = l
        },
    },
    actions: {
        setUserInfo (context, l) {
            context.commit('setUserInfo', l)
        },
        setFormRequired (context, l) {
            context.commit('setFormRequired', l)
        },
    },
    getters: {
        userInfo: (state) => state.user,
        formIsRequired: (state) => state.formRequired,
    },
};