export const apiLink = "https://apitest.offshore-cafe.com.ua"

export const appLangs = [
    {
        name: "ua",
        icon: "assets/images/ua.png",
    },
    {
        name: "en",
        icon: "assets/images/uk.png",
    },
]

export const locationMapLink = "https://maps.app.goo.gl/g3c6F2NEikTXpmfaA"

export const cookieExpireDays = 60

export const storageVersion = '1.1'