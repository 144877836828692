import { storageVersion } from '@/config'

export default {
    state: {
        favorites: JSON.parse(localStorage.getItem('favorites')) || [],
    },
    mutations: {
        addItemToFavorites(state, l) {
            state.favorites.push(l);
            localStorage.setItem("favorites", JSON.stringify(state.favorites));
        },
        removeItemFromFavorites(state, l) {
            state.favorites = state.favorites.filter((item) => item != l);
            localStorage.setItem("favorites", JSON.stringify(state.favorites));
        },
        restoreFavorites(state) {
            const currentStoreVersion = localStorage.getItem('osvf')
            if (currentStoreVersion != storageVersion) {
                state.favorites = [];
                localStorage.setItem("favorites", JSON.stringify(state.favorites));
                localStorage.setItem("osvf", storageVersion);
            }
        }
    },
    actions: {
        addItemToFavorites(context, l) {
            context.commit('addItemToFavorites', l)
        },
        removeItemFromFavorites(context, l) {
            context.commit('removeItemFromFavorites', l)
        },
        restoreFavorites(context) {
            context.commit('restoreFavorites')
        }
    },
    getters: {
        favorites: state => state.favorites,
        favoritesCount: state => state.favorites.length,
    },
};