import { createI18n } from 'vue-i18n'
import messages from './messages'
import store from '@/store'

// Set and expose the default locale
export const defaultLocale = 'ua'
// Private instance of VueI18n object
let _i18n
// Initializer
function setup(options = { locale: defaultLocale }) {
    var user_l = localStorage.getItem('oapp_lang');
    if(!user_l) {
        user_l = "ua";
        localStorage.setItem('oapp_lang',"ua");
    }
    _i18n = createI18n({
        locale: user_l,
        fallbackLocale: user_l,
        messages,
    })
    store.dispatch('setLanguage',user_l);
    setLocale(user_l);
    return _i18n;
}
// Sets the active locale. 
function setLocale(newLocale) {
    _i18n.global.locale = newLocale
}
// Public interface
export default {
    // Expose the VueI18n instance via a getter
    get vueI18n() {
        return _i18n
    },
    setup
}
