<template>
  <div class="mb-4">
    <v-text-field 
        class="ps-0 bs-0" 
        v-model="search_text" clearable
        :label="placeHolder" 
        prepend-inner-icon="mdi-magnify"
        variant="solo-filled"
        @keypress.enter="GoSearch"
        @click:clear="ClearSearch"
      >
      </v-text-field>
  </div>
</template>

<script>
import router from '@/router';
export default {
  name: "SearchComponent",
  props: {
    placeHolder: String
  },
  data() {
    return {
      search_text: ""
    }
  },
  computed: {

  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    "$store.state.searchString"(newValue) {
      this.search_text = newValue;
    }
  },
  methods: {
    ClearSearch() {
      this.search_text = ''
    },
    GoSearch() {
      if (!this.search_text || this.search_text.trim() == "" || this.search_text.length < 3) {
        this.$notify({
          title: this.$t("SearchWarningText"),
          type: 'warn',
          duration: 5000,
        });
      } else {
        router.push({ name: 'search', query: { s: this.search_text } })
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
