<template>
  <!-- Header -->
  <header class="header">
    <div class="main-bar">
      <div class="container">
        <div class="header-content">
          <div v-if="$store.state.page == 'HomeView'" class="left-content">
            <img @click="goHomeWithReload" class="logo light" :src="Logo" :alt="OwnerName">
            <img @click="goHomeWithReload" class="logo dark" :src="Logo" :alt="OwnerName">
          </div>
          <div v-else-if="$store.state.page == 'ProductsView' || $store.state.page == 'CategoriesView'"
            class="left-content">
            <a style="cursor: pointer;" @click.prevent="goCategoty" class="back-btn">
              <svg height="512" viewBox="0 0 486.65 486.65" width="512">
                <path
                  d="m202.114 444.648c-8.01-.114-15.65-3.388-21.257-9.11l-171.875-171.572c-11.907-11.81-11.986-31.037-.176-42.945.058-.059.117-.118.176-.176l171.876-171.571c12.738-10.909 31.908-9.426 42.817 3.313 9.736 11.369 9.736 28.136 0 39.504l-150.315 150.315 151.833 150.315c11.774 11.844 11.774 30.973 0 42.817-6.045 6.184-14.439 9.498-23.079 9.11z" />
                <path
                  d="m456.283 272.773h-425.133c-16.771 0-30.367-13.596-30.367-30.367s13.596-30.367 30.367-30.367h425.133c16.771 0 30.367 13.596 30.367 30.367s-13.596 30.367-30.367 30.367z" />
              </svg>
            </a>
            <h5 style="cursor: pointer;" @click.prevent="goCategoty" class="title mb-0 text-nowrap">
              {{ isRootCategory ? $t('AllCategories') : $store.state.preventCategoryName }}
            </h5>
          </div>
          <div v-else class="left-content">
            <a style="cursor: pointer;" @click.prevent="goHome" class="back-btn">
              <svg height="512" viewBox="0 0 486.65 486.65" width="512">
                <path
                  d="m202.114 444.648c-8.01-.114-15.65-3.388-21.257-9.11l-171.875-171.572c-11.907-11.81-11.986-31.037-.176-42.945.058-.059.117-.118.176-.176l171.876-171.571c12.738-10.909 31.908-9.426 42.817 3.313 9.736 11.369 9.736 28.136 0 39.504l-150.315 150.315 151.833 150.315c11.774 11.844 11.774 30.973 0 42.817-6.045 6.184-14.439 9.498-23.079 9.11z" />
                <path
                  d="m456.283 272.773h-425.133c-16.771 0-30.367-13.596-30.367-30.367s13.596-30.367 30.367-30.367h425.133c16.771 0 30.367 13.596 30.367 30.367s-13.596 30.367-30.367 30.367z" />
              </svg>
            </a>
            <h5 style="cursor: pointer;" @click.prevent="goHome" class="title mb-0 text-nowrap">{{ $t('ToHome') }}</h5>
          </div>

          <div class="mid-content"></div>
          <div class="right-content">
            <div class="theme-btn theme-social basic-dropdown theme-language-switcher">
              <div class="dropdown">
                <button type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="current_lang.icon" :alt="current_lang.name" />
                </button>
                <div v-if="ActualLangsChanger && ActualLangsChanger.length && ActualLangsChanger.length > 0"
                  class="dropdown-menu" style="">
                  <a v-for="lang in ActualLangsChanger" :key="lang.name" @click.prevent="changeLang(lang)"
                    class="dropdown-item" href="#"><img :src="lang.icon" :alt="lang.name" />&nbsp;&nbsp;{{
                      lang.name.toUpperCase() }}</a>
                </div>
              </div>
            </div>
            <a :href="Social ? 'tel:' + Social.phone : ''" class="theme-btn theme-social">
              <i class="fa-solid fa-phone dark"></i>
              <i class="fa-solid fa-phone light"></i>
            </a>
            <a v-if="Social && Social.fb" :href="Social ? Social.fb : ''" target="_blank"
              class="theme-btn theme-social">
              <i class="fa-brands fa-facebook dark"></i>
              <i class="fa-brands fa-facebook light"></i>
            </a>
            <a v-if="Social && Social.inst" :href="Social ? Social.inst : ''" target="_blank"
              class="theme-btn theme-social">
              <i class="fa-brands fa-instagram dark"></i>
              <i class="fa-brands fa-instagram light"></i>
            </a>
            <a href="javascript:void(0);" @click.prevent="changeMode" class="theme-btn theme-btn-switch-color">
              <svg class="dark" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <path
                  d="M11.57,2.3c2.38-0.59,4.68-0.27,6.63,0.64c0.35,0.16,0.41,0.64,0.1,0.86C15.7,5.6,14,8.6,14,12s1.7,6.4,4.3,8.2 c0.32,0.22,0.26,0.7-0.09,0.86C16.93,21.66,15.5,22,14,22c-6.05,0-10.85-5.38-9.87-11.6C4.74,6.48,7.72,3.24,11.57,2.3z" />
              </svg>
              <svg class="light" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
                viewBox="0 0 24 24" width="24px" fill="#000000">
                <rect fill="none" height="24" width="24" />
                <path
                  d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header -->
</template>
<script>
export default {
  name: "PagesHeader",
  props: {
    Langs: Array,
    Social: Object,
    Logo: String,
    OwnerName: String
  },
  data() {
    return {
      current_lang: {
        name: "ua",
        icon: "assets/images/ua.png"
      }
    }
  },
  computed: {
    ActualLangsChanger() {
      return this.Langs.filter((e) => {
        return e.name != this.current_lang.name;
      });
    },
    isRootCategory() {
      return !this.$route.params.aliace_sub_sec && !this.$route.params.aliace_cat
    }
  },
  methods: {
    changeMode() {
      jQuery('body').toggleClass('theme-dark');
      jQuery('.theme-btn-switch-color').toggleClass('active');
      jQuery('.theme-btn').toggleClass('active');
    },
    changeLang(crl) {
      this.current_lang = crl;
      localStorage.setItem('oapp_lang', this.current_lang.name);
      this.$i18n.locale = this.current_lang.name;
    },
    goAddress() {
      this.$router.push({ name: 'order.delivery' });
    },
    goOrder() {
      this.$router.push({ name: 'order' });
    },
    goHome() {
      this.$router.push({ name: 'home' });
    },
    goHomeWithReload() {
      location.reload();
    },
    goCategoty() {
      var aliace_sec = this.$store.state.preventCategoryAliace;
      this.$router.push({ path: aliace_sec });
    }
  },
  created() {
    var langStore = this.$store.state.language;
    var cur_ = this.Langs.filter((e) => {
      return e.name == langStore;
    });
    this.current_lang = cur_[0] || 'ua';
  },
  mounted() {

  }
};
</script>