<template>
  <!-- Preloader -->
  <div v-if="isLoading" id="preloader">
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <!-- Preloader end-->
</template>
<script>
export default {
  name: 'PreloaderOff',
  props: {
    isLoading: Boolean
  }
}
</script>
