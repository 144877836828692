import axios from 'axios'
import { apiLink } from '@/config'

export default {
    state:{
        settings: null,
    },
    mutations: {
        setSettings (state, l) {
            state.settings = l;
        },
    },
    actions: {
        async getSettings(context) {
            try {
                const settingsResponse = await axios.get(apiLink + "/api/public/settings")
                try {
                    settingsResponse.data.delivery_free = settingsResponse.data.delivery_free ? Number(settingsResponse.data.delivery_free) : null
                } catch (error) {
                    settingsResponse.data.delivery_free = null
                }
                try {
                    settingsResponse.data.delivery_price = settingsResponse.data.delivery_price ? Number(settingsResponse.data.delivery_price) : null
                } catch (error) {
                    settingsResponse.data.delivery_price = null
                }
                context.commit('setSettings', settingsResponse?.data || null)
            } catch (error) {
                context.commit('setSettings', null)
            }
        }
    },
    getters: {
        settings: state => state.settings,
    },
};